<template>
  <div>
    <b-row>
      <b-col lg="6">
        <ek-input-select
          clearable
          label="نوع الدرس"
          name="filter"
          placeholder="نوع الدرس"
          :options="[
            { id: '', name: 'الكل' },
            { id: 'Video', name: 'فيديو' },
            { id: 'Document', name: 'مستندات' },
          ]"
          @change="getLessonType"
          v-model="paginationLesson.lessonType"
        ></ek-input-select>
      </b-col>
    </b-row>
    <b-card class="p-0" body-class="p-0" header-class="p-1">
      <template #header>
        <b-button
          @click="deleteLessons"
          variant="flat-primary"
          class="rounded-circle p-25"
        >
          <unicon name="trash-alt" fill="red"></unicon>
        </b-button>
      </template>
      <vue-good-table
        styleClass="vgt-table"
        :columns="lessonsTableCols"
        :rows="lesson.lessons"
        :select-options="{ enabled: true }"
        @on-selected-rows-change="deleteRangeLessons"
      >
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field == 'dateCreated'">
            {{ new Date(props.row.dateCreated).toLocaleDateString() }}
          </span>
          <span v-else-if="props.column.field == 'url'">
            <b-button @click="downloadFile(props.row.url)" variant="primary"> تحميل </b-button>
          </span>
          <span v-else-if="props.column.field == 'details'">
            <b-button
              @click="goToDetails(props.row)"
              variant="flat-primary"
              class="rounded-circle p-25"
            >
              <unicon name="ellipsis-v" fill="gray"></unicon>
            </b-button>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
      <div class="d-flex w-100 justify-content-center my-1">
        <b-pagination
          class="m-auto"
          v-model="paginationLesson.pageIndex"
          pills
          :total-rows="lesson.count"
          :per-page="paginationLesson.pageSize"
          aria-controls="my-card"
          @change="paginate"
        ></b-pagination>
      </div>
    </b-card>
    <!-- <ek-table
            :columns="lessonsTableCols"
            :items="lesson.lessons"
            @details="goToDetails"
            @delete-selected="deleteRangLessons"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                {{ new Date(value).toLocaleDateString() }}
            </template>
            <template slot="items.url" slot-scope="{ value }">
                <b-button @click="downloadFile(value)" variant="primary">
                    تحميل
                </b-button>
            </template>
        </ek-table> -->
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import { mapState, mapActions, mapGetters } from "vuex";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
export default {
  components: {
    VueGoodTable,
  },
  data() {
    return {
      ids: [],
      // filterValueType: "",
      lessonsTableCols: [
        {
          label: "رقم الدرس ",
          field: "order",
        },
        {
          label: "اسم الدرس ",
          field: "name",
        },
        {
          label: "نوع الدرس ",
          field: "lessonType",
        },
        {
          label: "تابع ل",
          field: "followTo",
        },
        {
          label: "تحميل",
          field: "url",
        },
        {
          label: "تاريخ الاضافة",
          field: "dateCreated",
        },
        {
          label: "تفاصيل",
          field: "details",
        },
      ],
    };
  },
  computed: {
    ...mapState({
      lesson: ({ lessons }) => lessons.lesson,
      paginationLesson: ({ lessons }) => lessons.paginationLesson,
    }),
    ...mapGetters(["resultLessonList"]),
  },
  methods: {
    ...mapActions([
      "getLessons",
      "deleteLesson",
      "deleteRangeLesson",
      "getDepartmentsWithSubjects",
      "getFileLink"
    ]),
    deleteRangeLessons(row) {
      this.ids = row.selectedRows.map((ele) => ele.id);
      console.log(this.ids);
    },
    deleteLessons() {
      this.deleteRangeLesson(this.ids);
    },

    goToDetails(row) {
      this.$router.push(`/admin/lessons/${row.id}`);
    },
    getLessonType(e) {
      this.getLessons({
        lessonType: e,
        pageIndex: 1,
        pageSize: 6,
      });
    },
    paginate(e) {
      console.log(e);
      this.getLessons({
        lessonType: this.paginationLesson.lessonType,
        pageSize: 6,
        pageIndex: e,
      });
    },
    downloadFile(fileUrl) {
    console.log(`Download`, fileUrl);
    this.getFileLink(fileUrl).then(fl => {
        console.log(fl);
        window.open(fl, '_blank');
    });
},

    // async downloadFile(fileUrl, decrypted) {
    //   if (!decrypted) {
    //     fileUrl = await this.getFileLink(fileUrl);
    //   }
    //   axiosIns
    //     .get(fileUrl, {
    //       baseURL: this.$store.getters["app/domainHost"],
    //       responseType: "blob",
    //     })
    //     .then(({ data }) => {
    //       const downloadUrl = window.URL.createObjectURL(new Blob([data]));
    //       const link = document.createElement("a");

    //       link.href = downloadUrl;

    //       link.setAttribute("download", fileUrl); //any other extensio

    //       document.body.appendChild(link);

    //       link.click();

    //       link.remove();
    //     });
    // },
    async getDecryptedFile(fileUrl) {
      const { data } = await axiosIns.get(`File/getFileLink`, fileUrl);
      return data;
    },
  },

  created() {
    this.getLessons({
      lessonType: this.paginationLesson.lessonType,
      pageIndex: 1,
      pageSize: 6,
    });
  },
};
</script>

<style lang="scss">
.vgt-selection-info-row.clearfix {
  display: none !important;
}
.dark-layout table.vgt-table tbody{
    background-color:#161d31  !important
}
</style>
